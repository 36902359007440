import React, { useState, useEffect, useContext, useRef } from "react";
import Header from '../components/Header';
import { useLocation } from "react-router-dom";
import Select from 'react-select';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import useCategoryList from '../hooks/useCategoryList';
import Loading from '../components/Loading';
import Card from '../components/Card';
import MarkerMap from '../components/MarkerMap';
import ModalDetailStore from '../components/ModalDetailStore';
import GoogleMapReact from 'google-map-react';
import {
    orderStoreByLocation, ListUniqueStores, FilterTypeStore,
    getStoresByUserLocation, getListStoreFavorites, getVisibleMarkers
} from '../util/anyFunctions';
import UserContext from "../context/useLoginContext";
import iconList from '@img/icon-list.svg';
import iconMap from '@img/icon-map.svg';
import '@styles/search.scss';
import LazyLoad from 'react-lazyload';
import { internalTracking, storesByCategoryList } from '../api/index';
import { useQuery } from "react-query"
import CreatableSelect from 'react-select/creatable';
import useTagList from '../hooks/useTagList';
import { MyPositionMarker, PlaceholderCard, formatOptionLabel } from "../util/anyComponents";
import { options, amountPlaceholders, defaultProps } from "../util/constants";

const Search = () => {
    const { state } = useLocation();
    const location = useLocation();
    const [map, setMap] = useState(null);
    const [categories, setCategories] = useState([]);
    const [visibleMarkers, setVisibleMarkers] = useState([]);
    const [categoryValue, setCategoryValue] = useState(null);
    const [loading2, setLoading2] = useState(false);
    const [category, setCategory] = useState(state && state.filterId && JSON.stringify(state.filterId) !== '{}' ? state.filterId: 'available');
    const [stores, setStores] = useState([]);
    const [storesList, setStoreslist] = useState([]);
    const [storesOrigin, setStoresOrigin] = useState([]);
    const [storesListOrigin, setStoreslistOrigin] = useState([]);
    const [userLocation, setUserLocation] = useState(null);
    const [shouldCenterMap, setShouldCenterMap] = useState(true);
    const [centerMarker, setCenterMarker] = useState(null);
    const [opacity, setOpacity] = useState(1)
    const [dataStore, setDataStore] = useState(null);
    const [storefavorite, setstoreFavorite] = useState(null);
    const [showmap, setShwoMap] = useState(false);
    const { user } = useContext(UserContext);
    const [typeStore, setTypeStore] = useState(null);
    const [isOpenMoreFilters, setIsOpenMoreFilters] = useState(false);
    const [isDisableSelect, setIsDisableSelect] = useState(true);
    const [tags, setTags] = useState(null);
    const [tag, setTag] = useState(null);
    const [typeStoreOrigin, setTypeStoreOrigin] = useState([]);
    const [tagsOrigin, setTagsOrigin] = useState([]);
    const [tagsGlobal, setTagsGlobal] = useState([]);
    const [optionsTypeService, setOptionsTypeService] = useState(options)

    const wrapperRef = useRef(null);

    const handleClickMoreFilters = () => { setIsOpenMoreFilters(!isOpenMoreFilters) };

    const handleClickOutside = (event) => {

        if( event.target.className === "more-filters" ||
            event.target.className === "more-filter-name" ||
            event.target.className === "more-filter-name-svg"
        ){
            return;
        }

        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setIsOpenMoreFilters(false);
        }
    };

    const handleChangeTypeStore = (selectedOption) => {
        const arrayTypes = selectedOption?.length ? selectedOption.map(item => item.value) : [];
        filterStores({ types: arrayTypes, tags: tagsOrigin });
        setTypeStore(selectedOption);
        setTypeStoreOrigin(arrayTypes);
    };

    const handleChangeTag = (selectedOption) => {
        const arrayTags = selectedOption?.length ? selectedOption.map(item => item.label) : [];
        filterStores({ types: typeStoreOrigin, tags: arrayTags });
        setTag(selectedOption);
        setTagsOrigin(arrayTags);
    };

    const filterStores = ({ types = [], tags = [] }) => {
        const storesFiltered = storesOrigin.filter(store => {
            const matchesType = types.length ? types.includes(store.type) : true;
            const matchesTag = tags.length ? store.tags.some(tag => tags.includes(tag)) : true;
            return matchesType && matchesTag;
        });

        const storesListFiltered = storesListOrigin.filter(storeList => {
            const matchesType = types.length ? types.includes(storeList.type) : true;
            const matchesTag = tags.length ? storeList.tags.some(tag => tags.includes(tag)) : true;
            return matchesType && matchesTag;
        });

        const filterTags = [...new Set(storesListFiltered.flatMap(store => store.tags))];
        setTags(filterTags.length ? tagsGlobal.filter(labelObj => filterTags.includes(labelObj.label)) : tagsGlobal);
        setStores(storesFiltered);
        setStoreslist(storesListFiltered);
        setOptionsTypeService(FilterTypeStore(storesListFiltered, options));
    };

    useEffect(() => { window.dataLayer.push({event: "page_view"}) }, [location]);
    const getStoresUseQuery = async ({queryKey}) => {
        setLoading2(true)
        const {data} = await storesByCategoryList({ type: queryKey[1] })
        data.queryKey = queryKey[1]
        setLoading2(false)
        return data;
    }

    const { data, status } = useQuery({
        queryKey: ['stores', category?.value || 'available'],
        queryFn: getStoresUseQuery,
        staleTime: 10 * (60 * 1000),
        cacheTime: 15 * (60 * 1000),
    });

    const fetchDataStores = async () =>{
        setTag(null)
        setTypeStore(null)
        setIsDisableSelect(data.queryKey && data.queryKey !== "available"?  false : true)
        const {tags} = await useTagList(data.queryKey)
        setTags(tags)
        setTagsGlobal(tags)
        const stores = orderStoreByLocation(data.categories, userLocation?.lat, userLocation?.lng)
        const storeList = ListUniqueStores(stores)
        setVisibleMarkers(stores.slice(0, 30))
        setStores(stores);
        setStoresOrigin(stores);
        setStoreslist(storeList);
        setStoreslistOrigin(storeList);
        setOptionsTypeService(FilterTypeStore(storeList, options))
    }

    useEffect(() => {
        status === "success" && data && userLocation && fetchDataStores()
    }, [status, data, userLocation]);

    useEffect(() => {
        const fetchData = async () => {
            if (userLocation) {
                fetchTrackingData();
                const storeList = ListUniqueStores(getStoresByUserLocation(stores, userLocation))
                setStoreslist(storeList);
                setShouldCenterMap(false);
            }
        };
        fetchData();
    }, [userLocation]);

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation({ lat: latitude, lng: longitude });
                },
                (error) => {
                    console.error("Error getting user location:", error);
                    setUserLocation(defaultProps.center)
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
            setUserLocation(defaultProps.center)
        }
    };

    const fetchDataCategory = async () => {
        try {
            categories.length === 0 && await processDataCategory();
        } catch (error) {
            console.error("Error fetching stores:", error);
        }
    };

    useEffect(() => {
        fetchDataCategory();
        getUserLocation();
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (!map) return;

        const handleBoundsChange = () => {
            const bounds = map.getBounds();
            bounds && stores.length > 0 && setVisibleMarkers(getVisibleMarkers(stores, bounds));
        };

        map.addListener('dragend', handleBoundsChange);
        map.addListener('zoom_changed', handleBoundsChange);
        handleBoundsChange();

    }, [map, stores]);

    const fetchTrackingData = async (category=null) => {
        try {
            await internalTracking({ page: 'search', category,  lat: userLocation?.lat.toString(), lng: userLocation?.lng.toString()})
        } catch (error) {
            console.error('Error al obtener los datos:', error);
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                if(storefavorite && user){
                    const storeList = ListUniqueStores(getListStoreFavorites(storesList, storefavorite))
                    setStoreslist(storeList)
                }
            } catch (error) {
                console.error("Error fetching store when you set favorite store:", error);
            }
        };
        fetchData();
    }, [storefavorite, user]);

    const processDataCategory = async() =>{
        const [categoriesResponse] = await Promise.all([
            useCategoryList('available')
        ]);
        setCategories(categoriesResponse.categories);
    }

    const goTo = () => {
        setCenterMarker(null)
        category && fetchTrackingData(category.value)
    }

    const handleChange =  (selectedOption) => {
        setCategory(selectedOption)
        fetchTrackingData(selectedOption.value)
    };

    const changeMapList = async() =>{
        internalTracking({
            page: 'search', category: category.value, lat: userLocation?.lat.toString(),
            lng: userLocation?.lng.toString(), view: !showmap ? 'map' : 'list'
        })
        setShwoMap(!showmap)
    }


    const handleMapChange = ({ bounds }) => {
        stores.length > 0 && setVisibleMarkers(getVisibleMarkers(stores, bounds));
    };

    const handleMapLoaded = ({ map }) => { setMap(map) };

    return (
        <div className="search">
            <header className="header"><Header hiddeSearch={true}/></header>
            <div className='Content'>
                <div className='filters'>
                    <div className="search-carrousel">
                        <div className="select-search">
                            <div className="filter-search">
                                <Select
                                    className="select-input"
                                    classNamePrefix="select-input"
                                    options={categories}
                                    formatOptionLabel={formatOptionLabel}
                                    placeholder="Seleccionar el servicio"
                                    defaultValue={category}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="more-filters" onClick={handleClickMoreFilters}>
                                <div className="more-filter-name">Más filtros</div>
                                <div className="more-filter-name-svg"></div>
                            </div>
                            <div  ref={wrapperRef} className={`dropdown-container ${isOpenMoreFilters ? 'is-open' : ''}`}>
                                <div className='dropdown'>
                                    <div className='range-container'>
                                        <div className='left-input'>
                                            <label>Tipo de servicio:</label>
                                            <CreatableSelect
                                                name="typestore"
                                                isDisabled={isDisableSelect}
                                                isMulti
                                                value={typeStore}
                                                className="select-input2"
                                                classNamePrefix="select-input2"
                                                options={optionsTypeService}
                                                defaultValue={typeStore}
                                                placeholder="Selecciona algun tipo"
                                                onChange={handleChangeTypeStore}
                                                isValidNewOption={() => false}
                                            />
                                        </div>
                                        <div className='left-input'>
                                            <label>Caracteristicas:</label>
                                            <CreatableSelect
                                                isDisabled={isDisableSelect}
                                                name="categories"
                                                isMulti
                                                value={tag}
                                                className="select-input2"
                                                classNamePrefix="select-input2"
                                                options={tags}
                                                defaultValue={tag}
                                                placeholder="selecciona alguna caracteristica"
                                                onChange={handleChangeTag}
                                                isValidNewOption={() => false}
                                            />
                                        </div>
                                        {
                                            isDisableSelect &&
                                            <div className='left-input' style={{color:"#2f6dec"}}>
                                                * Por favor selecciona una categoría
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Search-SearchIcon" onClick={() => goTo()}>Buscar</div>
                    </div>
                </div>
                <div className="content-detail">
                    <div className={showmap ? "maps-content" : "maps-content display-none-mobile"} tabIndex={-1}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                            center={centerMarker ? centerMarker : shouldCenterMap ? defaultProps.center : userLocation}
                            options={{gestureHandling: 'greedy'}}
                            yesIWantToUseGoogleMapApiInternals
                            onDrag={() => setOpacity(0.1)}
                            onDragEnd={() => setOpacity(1)}
                            onChange={handleMapChange}
                            onGoogleApiLoaded={handleMapLoaded}
                        >
                            {
                                visibleMarkers.map((item, index) => (
                                    <MarkerMap
                                        lat={item.lat}
                                        lng={item.lng}
                                        img_src={item.iconMarker}
                                        storeId={item.id}
                                        key={index}
                                        opacity={opacity}
                                        categoryValue={item.categoryValue}
                                        setDataStore={setDataStore}
                                        setCategoryValue={setCategoryValue}
                                        setLoading2={setLoading2}
                                    />
                                ))
                            }
                            { userLocation && <MyPositionMarker lat={userLocation.lat} lng={userLocation.lng} /> }
                        </GoogleMapReact>
                    </div>
                    <div id="list-content" className={showmap ? "list-content display-none-mobile" : "list-content"}>
                        {
                            storesList.length >0 ?
                                storesList.map((item, index) => (
                                    <LazyLoad key={index} height={151} once scrollContainer={'.list-content'} >
                                        <Card
                                            name={item.name}
                                            id={item.id}
                                            lat={item.lat}
                                            lng={item.lng}
                                            type={item.type}
                                            category={item.categoryName}
                                            categoryValue={item.categoryValue}
                                            setCategoryValue={setCategoryValue}
                                            reference={item.reference}
                                            image={item.image}
                                            img_src={item.iconlist}
                                            storeId={item.id}
                                            setDataStore={setDataStore}
                                            isFavorite={item.isFavorite}
                                            setstoreFavorite={setstoreFavorite}
                                            setLoading2={setLoading2}
                                        />
                                    </LazyLoad>
                                ))
                            :
                                amountPlaceholders.map((_item, index) => ( <PlaceholderCard key={index}/> ))

                        }
                    </div>
                    <div className="button-mobile-map-list display-block-mobile" onClick={changeMapList}>
                    {
                        !showmap ?
                        <> Ver Mapa <img src={iconMap} alt="firullife-img"/> </>
                        :
                        <> Ver Lista <img src={iconList} alt="firullife-img"/> </>
                    }
                    </div>
                </div>
            </div>
            <ModalDetailStore
                dataStore={dataStore} setDataStore={setDataStore} categoryValue={categoryValue}
                setLoading2={setLoading2} setstoreFavorite={setstoreFavorite}
            />
            { loading2 && <Loading /> }
        </div>
    );
}

export default Search;
